import { images } from '@/asset';
import { Enum } from '@/constants';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import settingsSlice, { cssSelector, templateSelector } from '@/redux/slice/settings.slice';
import { Link, Spinner } from '@shopify/polaris';
import mixpanel from 'mixpanel-browser';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BlockPageStyled } from './styled';
import parse from 'html-react-parser';

const BlockPageStoreFront = ({ focusId, smallScreen }: { focusId: string; smallScreen?: boolean }) => {
  const template = useSelector(templateSelector);
  const isloading = useSelector(loadingSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const customCss = useSelector(cssSelector);
  const [getBlockTemplate] = apiCaller.useLazyGetBlockTemplateDataQuery();
  const dispatch = useDispatch();
  const [trackAction] = apiCaller.useTrackActionMutation();
  const removeWaterMark = () => {
    mixpanel?.track('Remove_watermark');
    const sendText = 'I want to remove brandmark in the blocking page';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
    trackAction('remove_water_mark');
  };
  useEffect(() => {
    getBlockTemplate().then((res) => {
      if (res.data) {
        dispatch(settingsSlice.actions.handleTemplate(res.data?.properties));
        dispatch(settingsSlice.actions.handleTemplateSaved(res.data?.properties));
        dispatch(settingsSlice.actions.handleCss(res.data?.customCss));
        dispatch(settingsSlice.actions.handleCssBackup(res.data?.customCss));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFree = useMemo(() => {
    if (dataSettings) {
      return dataSettings.settings.user.plan === Enum.UserPlan.FREE;
    }
    return true;
  }, [dataSettings]);

  return (
    <BlockPageStyled
      template={template}
      style={smallScreen ? { position: 'relative', zoom: 1 / 3 } : undefined}
      css={customCss}
      smallScreen={smallScreen}
      focusId={focusId}
    >
      {isloading ? (
        <div className="spiner-container">
          <Spinner />
        </div>
      ) : (
        <div id="blockify---container">
          <div id="blockify---container__inner">
            {template?.logoImage?.active && (
              <label
                htmlFor={!isFree ? `custom-template-logo` : undefined}
                id="blockify-logo-block-image"
                className="break-line pointer"
              >
                <img src={template?.logoImage.value} alt="logo" />
              </label>
            )}
            <div id="blockify-block-content">
              {template.superTitle?.active && (
                <label
                  htmlFor={!isFree ? `custom-template-superTitle` : undefined}
                  id="blockify-block-superTitle"
                  className="break-line pointer"
                >
                  {parse(template.superTitle?.text)}
                </label>
              )}
              <label
                htmlFor={!isFree ? `custom-template-title` : undefined}
                id="blockify-block-title"
                className="break-line pointer"
              >
                {parse(template?.title?.text)}
              </label>
              <label
                htmlFor={!isFree ? `custom-template-content` : undefined}
                id="blockify-block-description"
                className="break-line pointer"
              >
                {parse(template.description.text)}
              </label>
            </div>
          </div>
          {dataSettings?.settings.user.plan === Enum.UserPlan.FREE && dataSettings.settings.showWatermark ? (
            <div className="watermark-container">
              <div className="d-flex mt-16 mb-16">
                <div>Protected by</div>
                <img className="ml-4" src={images.logo} alt="blockify" />
                <div>Blockify™</div>
              </div>
              <Link onClick={removeWaterMark}>Click to remove brandmark</Link>
            </div>
          ) : null}
        </div>
      )}
    </BlockPageStyled>
  );
};
export default BlockPageStoreFront;
